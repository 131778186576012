import React, { createContext, useContext, useState } from "react";

const SiteContext = createContext();

export const useSiteContext = () => {
    return useContext(SiteContext);
};

export const SiteProvider = ({ children }) => {
    const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

    const toggleContactModal = () => {
        setContactModalIsOpen(!contactModalIsOpen);
    };

    const value = {
        contactModalIsOpen,
        toggleContactModal,
    };


    return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};
